<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table"
            title="설비 비가동 목록"
            :columns="grid.columns"
            :data="grid.data"
            :gridHeight="grid.height"
            :editable="editable"
            :hideBottom="true"
            :isExcelDown="false"
            :filtering="false"
            :isFullScreen="false"
            :columnSetting="false"
            selection="multiple"
            rowKey="equipmentOperationId"
            @table-data-change="tableDataChange"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addRow" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="grid.data"
                  mappingType="PUT"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="removeRow" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table2"
            title="설비 고장 목록"
            :columns="grid2.columns"
            :data="grid2.data"
            :gridHeight="grid2.height"
            :editable="editable"
            :hideBottom="true"
            :isExcelDown="false"
            :filtering="false"
            :isFullScreen="false"
            :columnSetting="false"
            selection="multiple"
            rowKey="equipmentBreakdownId"
            @table-data-change="tableDataChange2"
          >
            <template slot="suffixTitle">
              <font style="font-size:0.8em;font-weight:700;color:red">
                {{ '작업결과의 고장정보는 수정할 수 없습니다.'}}
              </font>
            </template>
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addRow2" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl2"
                  :isSubmit="isSave2"
                  :param="grid2.data"
                  mappingType="PUT"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData2"
                  @btnCallback="saveCallback2" />
                <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="removeRow2" />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'phenomenons'">
                <c-checkbox
                  :disabled="props.row['disable']"
                  :editable="editable"
                  codeGroupCd="WO_BD_PHENOMENON_CD"
                  valueText="codeName"
                  valueKey="code"
                  :isArray="true"
                  label=""
                  name="phenomenons"
                  v-model="props.row.phenomenons"
                  @datachange="datachange(props.row)"
                />
              </template>
              <template v-if="col.name === 'causes'">
                <c-checkbox
                  :disabled="props.row['disable']"
                  :editable="editable"
                  codeGroupCd="WO_BD_CAUSE_CD"
                  valueText="codeName"
                  valueKey="code"
                  :isArray="true"
                  label=""
                  name="causes"
                  v-model="props.row.causes"
                  @datachange="datachange(props.row)"
                />
              </template>
              <template v-if="col.name === 'actions'">
                <c-checkbox
                  :disabled="props.row['disable']"
                  :editable="editable"
                  codeGroupCd="WO_BD_ACTION_CD"
                  valueText="codeName"
                  :isArray="true"
                  valueKey="code"
                  label=""
                  name="actions"
                  v-model="props.row.actions"
                  @datachange="datachange(props.row)"
                />
              </template>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-operation-time',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
    editable: {
      type: Boolean,
      default: true,
    },
    revEquipmentCd: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      data: {
        plantCd: null,
        equipmentCd: '',  
        operatioStartDate: '', 
        holidayFlag: 'N', 
        operateTime: '', 
        operationList: [],
        breakList: [],
        deleteBreakList: [],
        lapseTime: '',
        operationSumTime: '',
        operationPercent: '',
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'breakNo',
            field: 'breakNo',
            label: '발생번호',
            align: 'right',
            type: 'text',
            style: 'width:100px',
            sortable: false,
          },
          {
            required: true,
            name: 'breakName',
            field: 'breakName',
            label: '가동정지명(정지사유)',
            align: 'left',
            type: 'text',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'breakTypeCd',
            field: 'breakTypeCd',
            label: '설비상태',
            align: 'center',
            style: 'width:300px',
            type: 'select',
            codeGroupCd: 'WO_EQUIP_STOP_CD',
            sortable: false,
          },
          {
            required: true,
            name: 'breakDtm',
            field: 'breakDtm',
            label: '가동정지일시',
            dateType: 'datetime',
            align: 'left',
            minuteStep: 30,
            range: true,
            type: 'date',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'breakTime',
            field: 'breakTime',
            label: '가동정지시간(h)',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            type: 'text',
            style: 'width:200px',
            sortable: false,
          },
        ],
        data: [],
        height: '250px',
      },
      grid2: {
        columns: [
          {
            required: true,
            name: 'breakRemark',
            field: 'breakRemark',
            label: '고장내역',
            align: 'left',
            type: 'textarea',
            style: 'width:200px',
            sortable: false,
          },
          {
            required: true,
            name: 'breakDtm',
            field: 'breakDtm',
            label: '고장 일시',
            dateType: 'datetime',
            align: 'left',
            minuteStep: 30,
            range: true,
            type: 'date',
            style: 'width:300px',
            sortable: false,
          },
          // {
          //   required: true,
          //   name: 'breakdownStartDtm',
          //   field: 'breakdownStartDtm',
          //   label: '고장 시작일시',
          //   dateType: 'datetime',
          //   align: 'left',
          //   minuteStep: 30,
          //   type: 'date',
          //   style: 'width:200px',
          //   sortable: false,
          // },
          // {
          //   required: true,
          //   name: 'breakdownEndDtm',
          //   field: 'breakdownEndDtm',
          //   label: '고장 종료일시',
          //   align: 'left',
          //   dateType: 'datetime',
          //   minuteStep: 30,
          //   type: 'date',
          //   style: 'width:200px',
          //   sortable: false,
          // },
          {
            name: 'breakdownTime',
            field: 'breakdownTime',
            label: '고장지속시간(h)',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'phenomenons',
            field: 'phenomenons',
            label: '현상',
            align: 'center',
            style: 'width:300px',
            type: 'custom',
            codeGroupCd: 'WO_BD_PHENOMENON_CD',
            sortable: false,
          },
          {
            name: 'causes',
            field: 'causes',
            label: '원인',
            align: 'center',
            style: 'width:300px',
            type: 'custom',
            codeGroupCd: 'WO_BD_CAUSE_CD',
            sortable: false,
          },
          {
            name: 'actions',
            field: 'actions',
            label: '조치',
            align: 'center',
            style: 'width:300px',
            type: 'custom',
            codeGroupCd: 'WO_BD_ACTION_CD',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '추가 조치 사항',
            align: 'left',
            type: 'textarea',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '고장사진',
            align: 'center',
            style: 'width:400px',
            type: 'attach',
            taskClassCd: 'EQUIPMENT_RESULT_FILE',
            keyText: 'equipmentBreakdownId',
            sortable: false,
          },
        ],
        data: [],
        height: '500px',
      },
      saveUrl2: '',
      deleteUrl2: '',
      isSave2: false,
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      holidayUrl: '',
      holidayList: [],
      breakUrl: '',
      sumBreakTime: 0,
      sumBreakTime2: 0,
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.$_.cloneDeep(this.param)
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    'revEquipmentCd'() {
      this.getOperate();
      this.getBreakDown();
    }
  },
  methods: {
    init() {
      // this.editable = this.$route.meta.editable;
      this.operateUrl = selectConfig.mdm.equipment.operate.list.url;
      this.breakUrl = selectConfig.mdm.equipment.breakdown.list.url;
      this.holidayUrl = selectConfig.sys.holiday.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.operate.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.operate.delete.url;
      this.saveUrl2 = transactionConfig.mdm.equipment.breakdown.save.url;
      this.deleteUrl2 = transactionConfig.mdm.equipment.breakdown.delete.url;
      
      this.getOperate();
      this.getBreakDown();
    },
    getBreakDown() {
      if (this.param.equipmentCd) {
        this.$http.url = this.breakUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          equipmentCd: this.revEquipmentCd === this.param.equipmentCd ? this.param.equipmentCd : this.revEquipmentCd,
          userId: this.$store.getters.user.userId,
        };
        this.$http.request((_result) => {
          this.grid2.data = _result.data
          if (this.grid2.data && this.grid2.data.length > 0) {
            this.$_.forEach(this.grid2.data, _item => {
              _item.breakDtm = [_item.breakdownStartDt + ' ' + _item.breakdownStartTime, 
                              _item.breakdownEndDt + ' ' + _item.breakdownEndTime ]
            })
          }
          this.$_.forEach(this.grid2.data, _item => {
            if (_item.causeList.length > 0) {
              this.$_.forEach(_item.causeList, item1 => {
                _item.causes.push(item1.resultTypeCd);
              });
            }
            if (_item.actionList.length > 0) {
              this.$_.forEach(_item.actionList, item2 => {
                _item.actions.push(item2.resultTypeCd);
              });
            }
            if (_item.causeList.length > 0) {
              this.$_.forEach(_item.phenomenonList, item3 => {
                _item.phenomenons.push(item3.resultTypeCd);
              });
            }
          })
        },);
      }
    },
    getOperate() {
      if (this.param.equipmentCd) {
        this.$http.url = this.operateUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          equipmentCd: this.revEquipmentCd === this.param.equipmentCd ? this.param.equipmentCd : this.revEquipmentCd,
          userId: this.$store.getters.user.userId,
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data
          if (this.grid.data && this.grid.data.length > 0) {
            this.$_.forEach(this.grid.data, _item => {
              _item.breakDtm = [_item.breakStartDt + ' ' + _item.breakStartTime, 
                              _item.breakEndDt + ' ' + _item.breakEndTime ]
            })
          }
        })
      }
    },
    tableDataChange(props) {
      if (props.row.breakDtm) {
        let start = this.$comm.moment(props.row.breakDtm[0])
        let end = this.$comm.moment(props.row.breakDtm[1])
        let diffMinutes = (this.$comm.moment.duration(end.diff(start)).asMinutes())
        let diffSeconds = parseInt(diffMinutes % 60);
        this.$set(props.row, 'breakTime', parseInt(diffMinutes / 60) + (diffSeconds == 30 ? '.5' : ''))
      }
    },
    addRow() {
      this.grid.data.push({
        equipmentOperationId: uid(),
        equipmentCd: this.param.equipmentCd,
        breakName: '',
        breakStartDt: '',
        breakEndDt: '',
        breakNo: '',
        breakStartTime: '',
        breakEndTime: '',
        breakTime: '',
        breakTypeCd: null,
        remark: '',
        regUserId: '',
        editFlag: 'C',
      })
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.data.regUserId = this.$store.getters.user.userId;
            this.data.chgUserId = this.$store.getters.user.userId;
            
            if (this.grid.data && this.grid.data.length > 0) {
              this.$_.forEach(this.grid.data, _item => {
                _item.breakStartDt = _item.breakDtm[0].split(' ')[0];
                _item.breakStartTime = _item.breakDtm[0].split(' ')[1];
                _item.breakEndDt = _item.breakDtm[1].split(' ')[0];
                _item.breakEndTime = _item.breakDtm[1].split(' ')[1];
              })
            }
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getOperate();
    },
    removeRow() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    tableDataChange2(props) {
      if (props.row.breakDtm) {
        let start = this.$comm.moment(props.row.breakDtm[0])
        let end = this.$comm.moment(props.row.breakDtm[1])
        let diffMinutes = (this.$comm.moment.duration(end.diff(start)).asMinutes())
        let diffSeconds = parseInt(diffMinutes % 60);
        this.$set(props.row, 'breakdownTime', parseInt(diffMinutes / 60) + (diffSeconds == 30 ? '.5' : ''))
      }
    },
    datachange(row) {
      if (row['editFlag'] !== 'C') {
        this.$set(row, 'editFlag', 'U')
      }
    },
    addRow2() {
      this.grid2.data.splice(0, 0, {
        equipmentBreakdownId: uid(),
        equipmentCd: this.param.equipmentCd,
        breakdownFlag: 'Y',
        breakdownStartDt: '',
        breakdownStartTime: '',
        breakdownEndDt: '',
        breakdownEndTime: '',
        breakdownTime: '',
        breakdownMttf: '',
        remark: '',
        breakRemark: '',
        editFlag: 'C',
        causeList: [],
        phenomenonList: [],
        actionList: [],
        causes: [],
        phenomenons: [],
        actions: [],
      })
    },
     saveData2() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
          this.$_.forEach(this.grid2.data, _item => {
            this.$http.url = this.breakUrl + '/prev';
            this.$http.type = 'GET';
            this.$http.param = {
              equipmentCd: this.param.equipmentCd,
              breakdownStartDt: _item.breakDtm[0].split(' ')[0],
              breakdownStartTime: _item.breakDtm[0].split(' ')[1],
            }
            this.$http.request((_result) => {
              _item.regUserId = this.$store.getters.user.userId;
              _item.chgUserId = this.$store.getters.user.userId;

              _item.breakdownStartDt = _item.breakDtm[0].split(' ')[0];
              _item.breakdownStartTime = _item.breakDtm[0].split(' ')[1];
              _item.breakdownEndDt = _item.breakDtm[1].split(' ')[0];
              _item.breakdownEndTime = _item.breakDtm[1].split(' ')[1];

              _item.causeList = [];
              _item.actionList = [];
              _item.phenomenonList = [];
              if (_item.causes.length > 0) {
                this.$_.forEach(_item.causes, item1 => {
                  _item.causeList.push({
                    equipmentBreakdownId: _item.equipmentBreakdownId,
                    breakdownResultTypeCd: 'CAUSE',
                    resultTypeCd: item1,
                  });
                });
              }
              if (_item.actions.length > 0) {
                this.$_.forEach(_item.actions, item2 => {
                  _item.actionList.push({
                    equipmentBreakdownId: _item.equipmentBreakdownId,
                    breakdownResultTypeCd: 'ACTION',
                    resultTypeCd: item2,
                  });
                });
              }
              if (_item.phenomenons.length > 0) {
                this.$_.forEach(_item.phenomenons, item3 => {
                  _item.phenomenonList.push({
                    equipmentBreakdownId: _item.equipmentBreakdownId,
                    breakdownResultTypeCd: 'PHENOMENON',
                    resultTypeCd: item3,
                  });
                });
              }
              
              if (_result.data) {
                let start = this.$comm.moment(_result.data.breakdownEndDtm)
                let end = this.$comm.moment(_item.breakDtm[0])
                let diffMinutes = (this.$comm.moment.duration(end.diff(start)).asMinutes())
                let diffSeconds = parseInt(diffMinutes % 60);
                _item.breakdownMttf = parseInt(diffMinutes / 60) + (diffSeconds == 30 ? '.5' : '');
              } else {
                _item.breakdownMttf = 0;
              }
            })
          });
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave2 = !this.isSave2;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
        },);
      }
    },
    saveCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getBreakDown();
    },
    removeRow2() {
      let selectData = this.$refs['table2'].selected;
      this.$_.forEach(this.grid2.data, _item => {
        this.$http.url = this.breakUrl + '/prev';
        this.$http.type = 'GET';
        this.$http.param = {
          equipmentCd: this.param.equipmentCd,
          breakdownStartDt: _item.breakDtm[0].split(' ')[0],
          breakdownStartTime: _item.breakDtm[0].split(' ')[1],
        }
        this.$http.request((_result) => {
          _item.regUserId = this.$store.getters.user.userId;
          _item.chgUserId = this.$store.getters.user.userId;
          _item.breakdownStartDt = _item.breakDtm[0].split(' ')[0];
          _item.breakdownStartTime = _item.breakDtm[0].split(' ')[1];
          _item.breakdownEndDt = _item.breakDtm[1].split(' ')[0];
          _item.breakdownEndTime = _item.breakDtm[1].split(' ')[1];

          _item.causeList = [];
          _item.actionList = [];
          _item.phenomenonList = [];
          if (_item.causes.length > 0) {
            this.$_.forEach(_item.causes, item1 => {
              _item.causeList.push({
                equipmentBreakdownId: _item.equipmentBreakdownId,
                breakdownResultTypeCd: 'CAUSE',
                resultTypeCd: item1,
              });
            });
          }
          if (_item.actions.length > 0) {
            this.$_.forEach(_item.actions, item2 => {
              _item.actionList.push({
                equipmentBreakdownId: _item.equipmentBreakdownId,
                breakdownResultTypeCd: 'ACTION',
                resultTypeCd: item2,
              });
            });
          }
          if (_item.phenomenons.length > 0) {
            this.$_.forEach(_item.phenomenons, item3 => {
              _item.phenomenonList.push({
                equipmentBreakdownId: _item.equipmentBreakdownId,
                breakdownResultTypeCd: 'PHENOMENON',
                resultTypeCd: item3,
              });
            });
          }
          
          if (_result.data) {
            let start = this.$comm.moment(_result.data.breakdownEndDtm)
            let end = this.$comm.moment(_item.breakDtm[0])
            let diffMinutes = (this.$comm.moment.duration(end.diff(start)).asMinutes())
            let diffSeconds = parseInt(diffMinutes % 60);
            _item.breakdownMttf = parseInt(diffMinutes / 60) + (diffSeconds == 30 ? '.5' : '');
          } else {
            _item.breakdownMttf = 0;
          }
        })
      });
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl2;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.isSave2 = !this.isSave2;
              this.$refs['table2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>